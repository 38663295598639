import * as React from 'react'
import Nav from "../components/nav";
import BackgroundImage from "../components/background-image";
import BGImage from "../images/contact-page-image.jpg";
import ContactForm from "../components/contact-form";
import Footer from "../components/footer";
import BeforeAndAfter from "../components/before-and-after";
import CtaBarAlternate from "../components/Cta-bar-alternate";
import CtaBar from "../components/cta-bar";
import {Helmet} from "react-helmet";

const Comparison = () => {
    return (
        <main>
            <Helmet>
                <title>Rancho Bernardo Maids - Before & After</title>
                <meta name="description" content="Rancho Bernardo Maids offers the best cleaning services, deep cleans, & move outs for houses, apartments, condos & more" />
                <meta name="keywords" content="Deep Cleaning, Standard House Cleaning, Move Outs, Party Clean Ups"/>
                <meta name="robots" content="index, follow" />
            </Helmet>
            <Nav/>
            <BackgroundImage image={BGImage} mobileImage={BGImage} alt={"forest background"} height={"h-128"}hero={true}>
                <h1 className={"text-5xl text-center text-white p-6"}>Our Work Speaks For Itself</h1>
                <p className={"text-center text-xl text-white"}>Check out our before & afters below</p>
            </BackgroundImage>
            <BeforeAndAfter/>
            <CtaBarAlternate/>
            <Footer/>
        </main>
    )
}

export default Comparison