import * as React from 'react'
import Before1 from "../images/before-1.jpg"
import After1 from "../images/after-1.jpg"
import Before2 from "../images/before-2.jpg"
import After2 from "../images/after-2.jpg"
import Before3 from "../images/before-3.jpg"
import After3 from "../images/after-3.jpg"

function BeforeAndAfterSet(props){
    return(
        <div className={"flex flex-row justify-center gap-5 py-5"}>
            <div>
                <img src={props.before} alt={"placeholder"} />
                <p className={"text-center text-malibu-700 dark:text-white font-light"}>Before</p>
            </div>
            <div>
                <img src={props.after} alt={"placeholder"} />
                <p className={"text-center text-malibu-700 dark:text-white font-light"}>After</p>
            </div>
        </div>
    )
}

function BeforeAndAfter(){
    return(
        <div className={"py-16 dark:bg-gray-900 dark:text-white"}>
            <h1 className={"text-3xl text-center text-malibu-700 dark:text-white pb-16"}>Before & After</h1>
            <BeforeAndAfterSet before={Before1} after={After1}/>
            <BeforeAndAfterSet before={Before2} after={After2}/>
            <BeforeAndAfterSet before={Before3} after={After3}/>
        </div>
    )
}

export default BeforeAndAfter